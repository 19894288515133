<template>
	<div>
            <b-row>
                <b-col cols="12" lg="12">
                    <b-form-group :label="$t('name')">
                            <b-input-group class="mb-1" prepend="TR">
                                <div class="label-as-input">{{ isNotNullValue(formData.name) }}</div>
                            </b-input-group>
                            <b-input-group prepend="EN">
                                <div class="label-as-input">{{ isNotNullValue(formData.name_en) }}</div>
                            </b-input-group>
                    </b-form-group>
                </b-col>
            </b-row>
            <b-row>
                <b-col>
                        <b-form-group :label="$t('university_type')">
                            <div class="label-as-input">{{ isNotNullValue(formData.type_name) }}</div>
                        </b-form-group>
                </b-col>
                <b-col>
                        <b-form-group :label="$t('country')">
                            <div class="label-as-input">{{ isNotNullValue(formData.country_name)}}</div>
                        </b-form-group>
                </b-col>
            </b-row>
	</div>
</template>

<script>
// Components

// Services
import UniversityService from "@/services/UniversityService";

// Other




	export default {
		components: {
         UniversityService
		},
		props: {
			formId: {
				type: Number,
			}
		},
		data() {
			return {
				formData: {},
				formLoading: false,
			}
		},

		async created() {
			await this.get(this.formId)
		},
		methods: {
			get(id) {
                UniversityService.get(id)
                              .then(response => {
                                  this.formData = response.data.data;
                              })
                              .catch(e => {
                                  this.showErrors(e)
                              })
			},


		}
	}
</script>
